<template>
  <div class="position-relative">
    <date-range-picker
      ref="picker"
      v-model="dateValue"
      class="form-control no-border"
      :opens="opens"
      :ranges="customRanges"
      :locale-data="localeData"
      :single-date-picker="singleDatePicker"
      :time-picker="timePicker"
      :time-picker24hour="timePicker24Hour"
      :show-week-numbers="showWeekNumbers"
      :show-dropdowns="showDropdowns"
      :linked-calendars="linkedCalendars"
      :always-show-calendars="false"
      :time-picker-increment="1"
      :auto-apply="true"
      @update="onUpdate"
    >
      <template v-slot:input="{ startDate, endDate }">
        <div class="date-picker-input">
          {{ startDate && endDate ? formatDate(startDate) + ' to ' + formatDate(endDate) : 'Select a date range' }}
        </div>
        <div
          v-if="startDate && endDate"
          class="datepicker-cancel"
          @click="clearDate"
        >
          <feather-icon icon="XIcon" />
        </div>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  name: 'DateRangePickerComponent',
  components: {
    DateRangePicker,
  },
  props: {
    dateValue: {
      type: Object,
      default: () => ({ startDate: null, endDate: null }),
    },
    opens: {
      type: String,
      default: 'center',
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    localeData: {
      type: Object,
      default: () => ({ firstDay: 1, format: 'yyyy-mm-dd HH:MM:ss' }),
    },
    singleDatePicker: {
      type: Boolean,
      default: false,
    },
    timePicker: {
      type: Boolean,
      default: true,
    },
    timePicker24Hour: {
      type: Boolean,
      default: true,
    },
    showWeekNumbers: {
      type: Boolean,
      default: true,
    },
    showDropdowns: {
      type: Boolean,
      default: true,
    },
    linkedCalendars: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 999)

    const yesterdayStart = new Date()
    yesterdayStart.setDate(today.getDate() - 1)
    yesterdayStart.setHours(0, 0, 0, 0)

    const yesterdayEnd = new Date()
    yesterdayEnd.setDate(today.getDate() - 1)
    yesterdayEnd.setHours(23, 59, 59, 999)

    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
    const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999)

    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      customRanges: {
        Today: [today, todayEnd],
        Yesterday: [yesterdayStart, yesterdayEnd],
        'This Month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999),
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999),
        ],
      },
    }
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format(this.dateFormat) : ''
    },
    onUpdate(newDateRange) {
      this.$emit('update', newDateRange)
    },
    onToggle(event) {
      this.$emit('toggle', event)
    },
    onStartSelection(event) {
      this.$emit('start-selection', event)
    },
    onFinishSelection(event) {
      this.$emit('finish-selection', event)
    },
    clearDate() {
      this.$emit('update', { startDate: null, endDate: null })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-daterangepicker.scss';
</style>
