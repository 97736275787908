<template>
  <b-card>
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          @submit.prevent="handleSubmit(createPackages)"
        >
          <b-row>
            <b-card
              title="Package Details"
              class="mb-0 w-100"
            >
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Package Name"
                    label-for="package-name"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="package-name"
                        v-model="form.package_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Package Name"
                        :disabled="readOnly"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Interval Value"
                    label-for="interval_value"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required|numeric"
                    >
                      <b-form-input
                        id="interval-value"
                        v-model="form.interval_value"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Interval Value"
                        :disabled="readOnly"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Interval Type"
                    label-for="interval"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                    >
                      <v-select
                        v-model="form.interval_type"
                        :state="errors.length > 0 ? false:null"
                        :options="intervalOptions"
                        :reduce="option => option.key"
                        label="name"
                        placeholder="Select Interval Type"
                        :disabled="readOnly"
                        deselect-from-dropdown
                      >
                        <template
                          v-slot:option="option"
                          class="text-truncate"
                        >
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Grace Days"
                    label-for="grace-days"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="grace-days"
                        v-model="form.grace_period"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Grace Period"
                        :disabled="readOnly"
                        pattern="[0-9]+"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Description"
                    label-for="description"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                    >
                      <quill-editor
                        id="description"
                        v-model="form.package_description"
                        :options="snowOption"
                        placeholder="Enter Package Description"
                        rows="6"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Start Date"
                    label-for="starts_at"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required-date"
                    >
                      <div class="position-relative">
                        <SingleAdvancedDatePicker
                          v-model="form.start_date"
                          :opens="'right'"
                          :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                          :single-date-picker="true"
                          :time-picker="false"
                          :time-picker24hour="false"
                          :date-format="'YYYY-MM-DD'"
                          @clear="clearDate"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label-for="is_active"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules=""
                    >
                      <b-form-checkbox
                        id="is_active"
                        v-model="form.is_active"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="readOnly"
                        style="margin-top:28px;"
                      >
                        Is Active
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group
                    label-for="is_public"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules=""
                    >
                      <b-form-checkbox
                        id="is_public"
                        v-model="form.is_public"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="readOnly"
                        style="margin-top:28px;"
                      >
                        Is Public
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group
                    label-for="has_trial"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules=""
                    >
                      <b-form-checkbox
                        id="has_trial"
                        v-model="form.has_trial"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="readOnly"
                        style="margin-top:28px;"
                      >
                        Has Trial
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group
                    label-for="is_default"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules=""
                    >
                      <b-form-checkbox
                        id="is_default"
                        v-model="form.is_default"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="readOnly"
                        style="margin-top:28px;"
                      >
                        Is Default
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    v-if="form.has_trial"
                    label="Enter trial length"
                    label-for="traial-length"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="traial-length"
                        v-model="form.trial_length"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter trial length"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <b-row>
            <div class="ml-2 mb-2">
              Package Rates
            </div>
          </b-row>
          <b-row
            md="6"
            style="margin-left:5px;"
          >
            <b-form-group
              label-for="payment_method"
            >
              <validation-provider
                #default="{ errors }"
                name="Billing Cycle"
                rules="required"
              >
                <label for="name">Select Billing Cycle</label>
                <v-select
                  v-model="form.method"
                  :options="paymentOptions"
                  :reduce="option => option.key"
                  label="name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Billing Cycle"
                  @change="setPaymentMethod"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
          <b-row
            v-for="(rate, index) in form.rates"
            :key="index"
          >
            <b-col
              md="2"
              style="margin-left:6px;"
            >
              <b-form-group
                label="From Count"
                label-for="from-count"
              >
                <ValidationProvider
                  #default="{ errors }"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="from-count"
                    v-model="rate['from_count']"
                    :disabled="readOnly"
                    placeholder="Enter From Count"
                  />
                  <small
                    v-if="index !== 0 && !fromCountValidity[index - 1]"
                    class="text-danger"
                  >
                    Invalid input: from_count must be larger than the previous to_count
                  </small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="To Count"
                label-for="to-count"
              >
                <ValidationProvider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="to-count"
                    v-model="rate['to_count']"
                    :disabled="readOnly || (index === lastIndex && form.is_unlimited)"
                    placeholder="Enter To Count"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Billing Cycle"
                label-for="billing-cycle"
              >
                <ValidationProvider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="to-count"
                    :value="getPaymentOptionName(form.method)"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Price"
                label-for="price"
              >
                <ValidationProvider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="price"
                    v-model="rate['price']"
                    placeholder="Enter Price"
                    :disabled="readOnly"
                    pattern="[0-9]+"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!readOnly"
              md="1"
              class="mt-2"
            >
              <div
                class="d-flex justify-content-start align-items-center"
              >
                <b-button
                  variant="danger"
                  class="mr-1"
                  @click="deleteRate(index)"
                >
                  <feather-icon
                    icon="MinusIcon"
                  />
                </b-button>
                <b-button
                  v-if="index == form.rates.length - 1"
                  variant="success"
                  :disabled="form.is_unlimited"
                  @click="addRate"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="ml-1"
          >
            <b-form-group
              label-for="is_unlimited_count"
            >
              <ValidationProvider
                #default="{ errors }"
                rules=""
              >
                <b-form-checkbox
                  id="is_unlimited_count"
                  v-model="form.is_unlimited"
                  :value="true"
                  :unchecked-value="false"
                  style="margin-top:28px;"
                  @change="updateUnlimitedCount"
                >
                  Is Unlimited Count
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <b-row
            v-if="form.modules.length > 0"
          >
            <div class="ml-2 mb-2">
              Module Selection
            </div>
          </b-row>
          <b-row
            v-for="(module, index) in filteredModulues"
            :key="index"
          >
            <b-col
              md="3"
              style="margin-left:6px;"
            >
              <b-form-group
                :label="index === 0 ? 'Module Name' : ''"
                label-for="from-count"
              >
                <b-form-checkbox
                  v-model="module.selected"
                  :value="true"
                  :unchecked-value="false"
                  style="margin-top:28px;"
                >
                  {{ module.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="index === 0 ? 'Payment Method' : ''"
                label-for="payment-method"
              >
                <ValidationProvider
                  #default="{ errors }"
                  rules="required_if:module.selected"
                >
                  <v-select
                    v-model="module.billing_cycle"
                    :options="paymentOptions"
                    :reduce="option => option.key"
                    label="name"
                    placeholder="Select Payment Method"
                    :disabled="readOnly"
                    deselect-from-dropdown
                    style="margin-top:18px;"
                  >
                    <template
                      v-slot:option="option"
                      class="text-truncate"
                    >
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="index === 0 ? 'Price($)' : ''"
                label-for="from-count"
              >
                <b-form-input
                  v-model="module.id"
                  hidden="true"
                />
                <b-form-input
                  id="price"
                  v-model="module.price"
                  :disabled="readOnly"
                  placeholder="Enter Price"
                  style="margin-top:18px;"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="(form.modules.length) > 1"
            style="margin-left:5px;"
          >
            <b-form-group
              label-for="is_unlimited_count"
            >
              <b-form-checkbox
                id="select_all_module"
                v-model="selectAll"
                :unchecked-value="false"
                @change="selectAllModule"
              >
                Select All
              </b-form-checkbox>
            </b-form-group>
          </b-row>
          <b-row
            v-if="!readOnly"
          >
            <!-- submit -->
            <b-col class="d-flex justify-content-end">
              <b-button
                v-can="'business-profile-manage'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1"
                :disabled="hasError"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max, numeric, required_if } from 'vee-validate/dist/rules'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import SingleAdvancedDatePicker from '@/components/AdvancedDateFilter/SingleAdvancedDatePicker.vue'
import moment from 'moment'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

extend('max', max)
extend('numeric', numeric)
extend('required_if', required_if)

const PackageRepository = RepositoryFactory.get('package')
const ResourceRepository = RepositoryFactory.get('resource')
const AddonRepository = RepositoryFactory.get('addon')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BCard,
    BOverlay,
    vSelect,
    SingleAdvancedDatePicker,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      fromCountValidity: [],
      snowOption: [],
      hasError: false,
      unlimited_count: false,
      lastIndex: '',
      selectAll: false,
      moduleName: false,
      loading: false,
      id: null,
      edit: false,
      readOnly: false,
      orderStatusOptions: [],
      form: {
        method: '',
        start_date: {
          startDate: null,
          endDate: null,
        },
        interval_value: '1',
        orderLimit: '',
        is_unlimited: false,
        data: {},
        rates: [
          {
            from_count: '',
            to_count: '',
            billing_cycle: '',
            price: '',
          },
        ],
        modules: [
          {
            billing_cycle: 'null',
            price: '',
          },
        ],
      },
      intervalOptions: [
        {
          key: 'month',
          name: 'Months',
        },
        {
          key: 'year',
          name: 'Years',
        },
      ],
      paymentOptions: [
        {
          key: 'per_order',
          name: 'Per Order',
        },
        {
          key: 'monthly',
          name: 'Monthly',
        },
      ],
    }
  },
  computed: {
    filteredModulues() {
      if (window.location.href.includes('edit') || window.location.href.includes('view')) {
        return this.form.modules
        // eslint-disable-next-line no-else-return
      } else {
        return this.form.modules.filter(module => module.active)
      }
    },
    formattedExpiryDate() {
      if (this.form.start_date && this.form.start_date.startDate) {
        return moment(this.form.start_date.startDate).format('YYYY-MM-DD')
      }
      return ''
    },
  },
  watch: {
    'form.rates': {
      handler() {
        this.updateFromCountValidity() // Update validation status when form.rates changes
      },
      deep: true,
    },
  },
  created() {
    if (window.location.href.includes('edit') || window.location.href.includes('view')) {
      if (window.location.href.includes('edit')) this.edit = true
      if (window.location.href.includes('view')) this.readOnly = true
      this.id = this.$route.params.id
      this.getPackageDetails()
    }
  },
  mounted() {
    if (window.location.href.includes('add-package') || window.location.href.includes('edit')) {
      this.getAddonList()
    }
  },
  methods: {
    setPaymentMethod() {
      const selectedBillingCycle = this.form.method === 'monthly' ? 'monthly' : 'perOrder'
      this.form.rates = this.form.rates.map(rate => ({
        ...rate,
        payment_method: this.form.method,
        billing_cycle: selectedBillingCycle,
      }))
    },
    getPaymentOptionName(key) {
      const paymentOptions = this.paymentOptions.find(option => option.key === key)
      return paymentOptions ? paymentOptions.name : ''
    },
    updateFromCountValidity() {
      this.fromCountValidity = [] // Clear previous validation statuses

      for (let i = 1; i < this.form.rates.length; i += 1) {
        const currentRate = this.form.rates[i]
        const previousRate = this.form.rates[i - 1]
        const isValid = currentRate.from_count > previousRate.to_count

        this.fromCountValidity.push(isValid)
      }
      this.hasError = this.fromCountValidity.some(validity => validity === false)
    },
    selectAllModule() {
      if (this.selectAll) {
        this.form.modules = this.form.modules.map(module => ({
          ...module,
          selected: true,
        }))
      } else {
        this.form.modules = this.form.modules.map(module => ({
          ...module,
          selected: false,
        }))
      }
    },
    updateUnlimitedCount() {
      if (this.form.is_unlimited) {
        this.form.is_unlimited = true
      } else {
        this.form.is_unlimited = false
      }
      this.lastIndex = (this.form.rates.length) - 1
      this.form.rates[this.lastIndex].to_count = ''
    },
    async readOrderStatuses() {
      this.orderStatusOptions = localStorage.getItem('order_statuses') ? JSON.parse(localStorage.getItem('order_statuses')) : (await ResourceRepository.getOrderStatusList()).data.data
      if (!localStorage.getItem('order_statuses')) localStorage.setItem('order_statuses', JSON.stringify(this.orderStatusOptions))
    },
    async createPackages() {
      this.loading = true
      if (this.form.is_public) {
        this.form.visibility = 'public'
      } else {
        this.form.visibility = 'private'
      }
      try {
        this.lastIndex = (this.form.rates.length) - 1
        this.form.orderLimit = this.form.rates[this.lastIndex].to_count
        const payload = {
          package_name: this.form.package_name,
          package_description: this.form.package_description,
          start_date: this.formattedExpiryDate,
          order_limit: this.form.orderLimit,
          interval_type: this.form.interval_type,
          interval_value: this.form.interval_value,
          visibility: this.form.visibility,
          grace_period: this.form.grace_period,
          is_active: this.form.is_active || false,
          is_unlimited: this.form.is_unlimited || false,
          has_trial: this.form.has_trial || false,
          trial_length: this.form.has_trial ? this.form.trial_length : null,
          is_default: this.form.is_default || false,
          rates: this.form.rates.map(rate => ({
            ...rate,
            billing_cycle: this.form.method,
          })),
          addons: this.form.modules.filter(module => module.selected),
        }
        let res
        if (this.edit) res = await PackageRepository.updatePackage(this.id, payload)
        else res = await PackageRepository.createPackage(payload)
        if (res.status === 201 || res.status === 200) {
          this.showSuccessMessage(`Package ${this.edit ? 'Updated' : 'Created'} Successfully`)
          this.$router.push({ name: 'all-packages' })
        } else {
          this.showErrorMessage(`Couldn't ${this.edit ? 'update' : 'create'} package`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getPackageDetails() {
      this.loading = true
      try {
        const { data } = await PackageRepository.getPackageDetails(this.id)
        this.form.package_name = data.package_name
        this.form.method = data.package_rates[0].billing_cycle
        this.form.interval_value = data.interval_value
        this.form.interval_type = data.interval_type
        this.form.grace_period = data.grace_period
        this.form.package_description = data.package_description
        this.form.start_date.startDate = moment(data.start_date, 'YYYY-MM-DD').toDate()
        this.form.start_date.endDate = moment(data.start_date, 'YYYY-MM-DD').toDate()
        this.form.is_active = data.is_active
        this.form.has_trial = data.has_trial
        this.form.is_unlimited = data.is_unlimited
        this.form.trial_length = data.trial_length
        this.form.rates = data.package_rates
        this.form.modules = data.package_addons
        if (data.visibility === 'public') {
          this.form.is_public = true
        }
        this.$forceUpdate()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    addRate() {
      this.form.rates.push({
        from_count: '',
        to_count: '',
        billing_cycle: this.form.rates.billing_cycle,
        price: '',
      })
      this.$forceUpdate()
    },
    deleteRate(index) {
      if (this.form.rates.length > 1) this.form.rates.splice(index, 1)
      this.$forceUpdate()
    },
    async getAddonList() {
      this.loading = true
      try {
        // eslint-disable-next-line no-underscore-dangle
        const { data } = (await AddonRepository.getAddonListNopagination())
        data.data.forEach(module => {
          this.form.modules.push({
            addon_id: module.id,
            name: module.addon_name,
            billing_cycle: '',
            price: '',
            selected: false,
            active: module.is_active,
          })
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearDate() {
      this.form.start_date = {
        startDate: null,
        endDate: null,
      }
    },
  },
}
</script>
<style lang="scss">
</style>
