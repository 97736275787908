<template>
  <div class="position-relative">
    <date-range-picker
      ref="picker"
      v-model="value"
      class="form-control no-border"
      :opens="opens"
      :locale-data="localeData"
      :single-date-picker="singleDatePicker"
      :time-picker="timePicker"
      :time-picker24hour="timePicker24Hour"
      :show-week-numbers="showWeekNumbers"
      :show-dropdowns="showDropdowns"
      :linked-calendars="linkedCalendars"
      :ranges="false"
      :auto-apply="true"
      :time-picker-increment="1"
    >
      <template v-slot:input="{ startDate}">
        <div class="date-picker-input">
          {{ startDate ? formatDate(startDate) : 'Select a date' }}
        </div>
        <div
          v-if="startDate && clearIcon"
          class="datepicker-cancel"
          @click="clearDate"
        >
          <feather-icon icon="XIcon" />
        </div>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  name: 'DateRangePickerComponent',
  components: {
    DateRangePicker,
  },
  props: {
    value: {
      type: Object,
      default: () => ({ startDate: null, endDate: null }),
    },
    opens: {
      type: String,
      default: 'center',
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss',
    },
    localeData: {
      type: Object,
      default: () => ({ firstDay: 1, format: 'yyyy-mm-dd HH:MM:ss' }),
    },
    singleDatePicker: {
      type: Boolean,
      default: false,
    },
    timePicker: {
      type: Boolean,
      default: true,
    },
    timePicker24Hour: {
      type: Boolean,
      default: true,
    },
    showWeekNumbers: {
      type: Boolean,
      default: true,
    },
    showDropdowns: {
      type: Boolean,
      default: true,
    },
    linkedCalendars: {
      type: Boolean,
      default: false,
    },
    clearIcon: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(value) {
      this.$emit('input', value)
    },
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format(this.dateFormat) : ''
    },
    onUpdate(newDateRange) {
      this.$emit('update', newDateRange)
    },
    onToggle(event) {
      this.$emit('toggle', event)
    },
    onStartSelection(event) {
      this.$emit('start-selection', event)
    },
    onFinishSelection(event) {
      this.$emit('finish-selection', event)
    },
    clearDate() {
      this.$emit('clear', { startDate: null, endDate: null })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-daterangepicker.scss';
</style>
